$icon-padding: 0.5rem;
$icon-padding-lg: 1rem;

@mixin btn-reset() {
    background-color: transparent;
    border: none;
    appearance: none;
    cursor: pointer;
    &:focus {
        outline: none;
        border: none;
        box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.25);
    }
    &.white {
        &:focus {
            box-shadow: 0 0 0 4px rgba(white, 0.25);
        }
    }
    &.filter-text {
        &:focus {
            box-shadow: 0 0 0 4px rgba($body-color, 0.25);
        }
    }
}

@mixin btn-position-right() {
    position: absolute;
    top: 1rem;
    right: 1rem;
    &.position-tight {
        top: .5rem;
        right: .5rem;
    }
}

@mixin btn-negative-padding() {
    margin: -$icon-padding;
    @include media-breakpoint-up(md) {
        margin: -$icon-padding-lg;
    }
}

@mixin btn-icon() {
    padding: $icon-padding;
    border-radius: 0.5rem;
    width: 2rem;
    height: 2rem;
    position: relative;
    @include btn-reset();
    @include media-breakpoint-up(md) {
        padding: $icon-padding-lg;
        width: 3.5rem;
        height: 3.5rem;
    }
}

// Component - Buttons
@function color-yiq($color) {
    @return $white;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    &:not(.btn):not(.close-me) {
        appearance: none;
        border: none;
    }
    &:not(:hover) {
        background-color: transparent;
    }
}

.btn {
    cursor: pointer;
}

@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-variant(
            $value,
            // Background
            $value,
            // Border
            darken($value, 5%),
            // Hover Background
            darken($value, 5%),
            // Hover Border
            darken($value, 10%),
            // Active Background
            darken($value, 10%) // Active Border
        );
    }
}

.btn-icon-suffix .fa {
    margin-left: $spacer / 2;
}

.btn-icon-prefix .fa {
    margin-right: $spacer / 2;
}

.btn + .btn {
    margin-left: $spacer / 2;
}

.btn-light {
    background-color: #dee2e6;
    color: #616161;
    border-color: #dee2e6;
}

.btn-success:disabled {
    pointer-events: none;
}

.btn-plain {
    @include btn-reset();
    color: inherit;
    text-align: inherit;
    line-height: inherit;
    margin: calc(#{$input-btn-padding-y} * -1)
        calc(#{$input-btn-padding-x} * -1);
    &.white {
        &,
        * {
            color: white;
        }
    }
    &.filter-text {
        &,
        * {
            color: $body-color;
        }
    }
    &.btn-sm {
        margin: calc(#{$input-btn-padding-y-sm} * -1)
            calc(#{$input-btn-padding-x-sm} * -1);
    }
}

.close-me {
    @include btn-icon();
    background-color: rgba(black, 0);
    transition: background-color $transition-default ease;
    &.position-right {
        @include btn-position-right();
    }
    &::before,
    &::after {
        content: "";
        display: block;
        width: 1rem;
        height: 2px;
        background-color: $headings-color;
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 1px;
        transition: transform $transition-slow ease;
        @include media-breakpoint-up(md) {
            width: 1.5rem;
        }
    }
    &::before {
        transform: translate(-50%, -50%) rotate(45deg);
        .close-me:hover & {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
    &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
        .close-me:hover & {
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }
    &:hover {
        background-color: rgba(black, 0.1);
        
        &::before {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
        &::after {
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }
    &.white {
        &::before,
        &::after {
            background-color: white;
        }
    }
    &.filter-text {
        &::before,
        &::after {
            background-color: $body-color;
        }
    }
}

@keyframes filter-in-circle-odd {
    0% {
        opacity: 0;
        transform: translateX(1rem);
    }
    60% {
        transform: translateX(0);
        opacity: 1;
    }
    80% {
        transform: translateX(0.4rem);
    }
    100% {
        transform: translateX(0.25rem);
    }
}

@keyframes filter-in-circle-even {
    0% {
        opacity: 0;
        transform: translateX(0);
    }
    60% {
        transform: translateX(1rem);
        opacity: 1;
    }
    80% {
        transform: translateX(0.6rem);
    }
    100% {
        transform: translateX(0.75rem);
    }
}

@keyframes filter-in-line-odd {
    0% {
        opacity: 0;
        width: 0;
        transform: translateX(1.5rem);
    }
    20% {
        opacity: 0;
    }
    60% {
        width: 1.5rem;
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        width: 1.5rem;
        transform: translateX(0);
    }
}

@keyframes filter-in-line-even {
    0% {
        opacity: 0;
        width: 0;
    }
    20% {
        opacity: 0;
    }
    60% {
        width: 1.5rem;
        opacity: 1;
    }
    100% {
        width: 1.5rem;
    }
}

@keyframes filter-out-circle-odd {
    0% {
        transform: translateX(0.25rem);
    }
    100% {
        opacity: 0;
        transform: translateX(1rem);
    }
}

@keyframes filter-out-circle-even {
    0% {
        transform: translateX(0.75rem);
    }
    100% {
        opacity: 0;
        transform: translateX(0);
    }
}

@keyframes filter-out-line-odd {
    0% {
        opacity: 1;
        width: 1.5rem;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        width: 0;
        transform: translateX(1.5rem);
    }
}

@keyframes filter-out-line-even {
    0% {
        width: 1.5rem;
    }
    100% {
        opacity: 0;
        width: 0;
    }
}

@keyframes filter-hover-circle-odd {
    0% {
        transform: translateX(0);
    }
    60% {
        transform: translateX(1rem);
    }
    80% {
        transform: translateX(0.6rem);
    }
    100% {
        transform: translateX(0.75rem);
    }
}

@keyframes filter-hover-circle-even {
    0% {
        transform: translateX(1rem);
    }
    60% {
        transform: translateX(0);
    }
    80% {
        transform: translateX(0.4rem);
    }
    100% {
        transform: translateX(0.25rem);
    }
}

.filter {
    @include btn-icon();
    @include btn-position-right();
    padding: 0.375rem 0.25rem;
    color: $body-color;
    @include media-breakpoint-up(md) {
        padding: 1rem 0.75rem;
    }
    @include media-breakpoint-up(xl) {
        width: unset;
        height: unset;
    }
    .filter-label {
        display: none;
        @include media-breakpoint-up(xl) {
            display: inline-block;
            margin-right: 0.5rem;
            text-transform: uppercase;
            color: $body-color;
            font-size: 0.75rem;
            font-weight: 600;
        }
    }
    .filter-label,
    .filter-icon {
        vertical-align: middle;
    }
    .filter-icon {
        position: relative;
        display: inline-block;
    }
    span {
        display: block;
        width: 1.5rem;
        height: 0.25rem;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        &:first-child {
            margin-top: 0.125rem;
            @include media-breakpoint-up(md) {
                margin-top: 0.25rem;
            }
        }
        &:not(:last-child) {
            margin-bottom: 0.25rem;
        }
        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
        }
        &::before {
            // Circle
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 0.25rem;
            border: 2px solid;
            background-color: $body-color;
            border-color: $headings-color;
            z-index: 2;
            top: -0.25rem;
        }
        &::after {
            // Line
            background-color: $headings-color;
            width: 1.5rem;
            height: 2px;
            border-radius: 1px;
            z-index: 1;
            top: -1px;
        }
    }
    &.show {
        opacity: 1;
        span:nth-child(1),
        span:nth-child(3) {
            &::before {
                animation: filter-in-circle-odd $transition-slow forwards linear;
            }
            &::after {
                animation: filter-in-line-odd $transition-slow forwards linear;
            }
        }
        span:nth-child(2) {
            &::before {
                animation: filter-in-circle-even $transition-slow forwards
                    linear;
            }
            &::after {
                animation: filter-in-line-even $transition-slow forwards linear;
            }
        }
        &:hover {
            span:nth-child(1),
            span:nth-child(3) {
                &::before {
                    animation: filter-hover-circle-odd $transition-slow forwards
                        linear;
                }
            }
            span:nth-child(2) {
                &::before {
                    animation: filter-hover-circle-even $transition-slow
                        forwards linear;
                }
            }
        }
    }
    &:not(.show) {
        opacity: 0;
        transform: opacity $transition-slow ease;
        pointer-events: none;
        span:nth-child(1),
        span:nth-child(3) {
            &::before {
                animation: filter-out-circle-odd $transition-slow forwards
                    linear;
            }
            &::after {
                animation: filter-out-line-odd $transition-slow forwards linear;
            }
        }
        span:nth-child(2) {
            &::before {
                animation: filter-out-circle-even $transition-slow forwards
                    linear;
            }
            &::after {
                animation: filter-out-line-even $transition-slow forwards linear;
            }
        }
    }
    &.white {
        span {
            &::before {
                background-color: $primary;
                border-color: white;
            }
            &::after {
                background-color: white;
            }
        }
    }
    &.filter-text {
        span {
            &::before {
                background-color: $primary;
                border-color: $body-color;
            }
            &::after {
                background-color: $body-color;
            }
        }
    }
}

.back {
    @include btn-icon();
    @include btn-negative-padding();
    transform: rotate(0deg);
    transition: $transition-slow ease-in-out;
    width: 2.5rem;
    height: 2.5rem;
    span {
        display: block;
        position: absolute;
        height: 0.125rem;
        background: $headings-color;
        border-radius: 1px;
        transform: rotate(0deg);
        transition: 0.2s ease-in-out;
        &:nth-child(1),
        &:nth-child(3) {
            width: 0.75rem;
        }
        &:nth-child(1) {
            right: 50%;
            bottom: calc(50% - 1px);
            transform-origin: right;
            transform: translateX(-90%) rotate(135deg);
        }
        &:nth-child(2) {
            width: 1.5rem;
            transform: translate(-50%, -50%) rotate(0deg);
            top: 50%;
            left: 50%;
        }
        &:nth-child(3) {
            left: 50%;
            top: calc(50% - 1px);
            transform-origin: left;
            transform: translateX(-90%) rotate(45deg);
        }
    }
    &:hover {
        span {
            &:nth-child(1),
            &:nth-child(3) {
                transform: translateX(0) rotate(45deg);
            }
            &:nth-child(2) {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }
}

.kebab {
    @include btn-icon();
    @include btn-negative-padding();
    &::after,
    &::before {
        content: "";
    }
    &::after,
    &::before,
    span {
        width: 3px;
        height: 3px;
        border-radius: 1.5px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: $headings-color;
        transition: all $transition-slow;
    }
    &::before {
        transform: translate(-50%, -0.5rem) rotate(0deg);
        @include media-breakpoint-up(md) {
            transform: translate(-50%, -0.75rem) rotate(0deg);
        }
    }
    span {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    &::after {
        transform: translate(-50%, 5px) rotate(0deg);
        @include media-breakpoint-up(md) {
            transform: translate(-50%, 9px) rotate(0deg);
        }
    }
    .show & {
        &::after,
        &::before {
            width: 1rem;
            height: 0.125rem;
            border-radius: 1px;
            @include media-breakpoint-up(md) {
                width: 1.5rem;
            }
        }
        &::before {
            transform: translate(-50%, -50%) rotate(225deg);
        }
        &::after {
            transform: translate(-50%, -50%) rotate(135deg);
        }
    }
}

.discrete {
    &,
    &-icon {
        color: inherit;
    }
    &-icon {
        position: relative;
        padding-left: 1.5rem;
        display: block;
        i {
            position: absolute;
            left: 0;
            top: 0.25rem;
            width: 1rem;
            height: 1rem;
            text-align: center;
        }
    }
}

.btn-outline-white {
    @include button-outline-variant(white);
}