.form-control {
    &:disabled,
    &[readonly],
    & {
        background-color: mix($primary, black, 80%);
    }
    transition: background-color $transition-slow ease;
    border-radius: 0;
    border-width: 0;
    padding: 0.125rem 0.5rem;
    margin: -0.125rem -0.5rem;
    width: calc(100% + 1rem);
    color: inherit;
    height: unset;
    font-weight: 700;
    &:focus {
        color: inherit;
        box-shadow: none;
    }
    &:first-of-type {
        border-top-left-radius: $border-radius-sm;
        border-top-right-radius: $border-radius-sm;
    }
    &:last-of-type {
        border-bottom-left-radius: $border-radius-sm;
        border-bottom-right-radius: $border-radius-sm;
    }
    fieldset:disabled & {
        &:disabled,
        &[readonly],
        & {
            background-color: mix($primary, black, 100%);
        }
    }
}

div.form-control {
    background-color: transparent;
}

label {
    font-size: $font-size-sm;
    margin-bottom: 0;
}

.form-group {
    span.label {
        @extend label;
    }
}
