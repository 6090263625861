aside {
    &.navigation {
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 10;
        left: -112px;
        width: 100vw;
        max-width: 112px;
        transform: translateX(1rem);
        transition: transform $transition-slow ease,
            max-width $transition-slow ease-out;
        padding: calc(1rem + env(safe-area-inset-top)) 1rem
            calc(1rem + env(safe-area-inset-bottom));
        cursor: grab;
        .showSubmenu & {
            max-width: calc(300px + 80px + 1rem + 2rem);
        }
        .showNav & {
            transform: translateX(112px);
        }
        @media (pointer: fine) {
            &:hover {
                transform: translateX(112px);
            }
            &::after {
                content: "";
                display: block;
                width: 0.25rem;
                height: 30%;
                border-radius: 1.25rem;
                background-color: rgba(0, 0, 0, 0.3);
                position: absolute;
                right: 0.25rem;
                top: 35%;
                z-index: 8;
            }
        }
        .nav-wrapper {
            position: relative;
            border-radius: 1.5rem;
            background-color: rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(16px);
            width: 100%;
            height: 100%;
            padding: 0.5rem 0.5rem 0.5rem 4.5rem;
            transition: padding 50ms linear ($transition-slow - 50ms);
            .showSubmenu & {
                padding: 0.5rem 0.5rem 0.5rem 5rem;
                transition: padding 50ms ease;
            }
            .primary-nav {
                position: absolute;
                top: 1rem;
                bottom: 1rem;
                left: 0.75rem;
                right: 0.75rem;
                width: 56px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
            }
        }
        .submenu {
            width: 100%;
            height: 100%;
            background-color: rgba(black, 0.4);
            backdrop-filter: blur(16px);
            z-index: 9;
            border-radius: 0 1rem 1rem 0;
            transition: max-width $transition-slow ease;
            box-shadow: 0 0 1rem rgba(black, 0.3) inset;
            padding: 0;
            position: relative;
            .showSubmenu & {
                padding: 1rem;
            }
            > nav {
                color: white;
                height: 100%;
                display: flex;
                flex-direction: column;
                // justify-content: center;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                
                .submenu-list {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    .nav-item {
                        color: white;
                        display: block;
                        padding: 0.75rem 1rem 0.75rem 3.5rem;
                        .material-symbols-rounded {
                            position: absolute;
                            top: 0.75rem;
                            left: 1rem;
                            // font-size: 1rem;
                        }
                        .submenu-name {
                            margin: 3px 0;
                            line-height: 1.2rem;
                            text-transform: uppercase;
                            font-weight: 600;
                            display: inline-block;
                            font-size: $font-size-sm;
                            letter-spacing: 0.5px;
                        }
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
            > nav,
            .close-me {
                opacity: 0;
                visibility: hidden;
                transition: opacity $transition-slow ease;
                .showSubmenu & {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}


.submenu > nav::-webkit-scrollbar {
    width: 4px;
    height: 4px; /* width of the entire scrollbar */
    // overflow-y: overlay;
}
.submenu > nav::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4); /* color of the scroll thumb */
    border-radius: 6px; /* roundness of the scroll thumb */
    border: none; /* creates padding around scroll thumb */
}
