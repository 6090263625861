$primary: #38b6ab;
$body-color: #555;
$text-muted: #999;

$success:       #28a745 !default;
$warning:       #ffc107 !default;
$danger:        #dc3545 !default;

$body-bg: mix($primary, white, 10%);

$font-family-sans-serif: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
    $font-family-monospace: 'Source Code Pro', SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$headings-font-weight: 700 !default;

$box-shadow-sm: 0 0.125rem 0.25rem rgba(black, 0.075) !default;
$box-shadow: 0.5rem 1rem 2rem rgba(black, 0.1) !default;
$box-shadow-lg: 0 1rem 3rem rgba(black, 0.175) !default;

$border-radius: 1rem !default;

$card-border-color: transparent !default;

$grid-gutter-width: 1rem !default;
$container-padding-x: $grid-gutter-width !default;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .75 !default;

$h1-font-size:                $font-size-base * 2 !default;
$h2-font-size:                $font-size-base * 1.75 !default;
$h3-font-size:                $font-size-base * 1.5 !default;
$h4-font-size:                $font-size-base * 1.25 !default;
$h5-font-size:                $font-size-base * 1.125 !default;
$h6-font-size:                $font-size-base !default;

$table-border-color: transparent !default;
$table-hover-bg: white !default;

//---------- Grid breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1700px
);


//---------- Grid containers
$container-max-widths: (
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1500px
);

// Transitions
$transition-default: 200ms;
$transition-fast: $transition-default / 2;
$transition-slow: $transition-default * 2;