@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "./components/component-styles.scss";

.container,
.container-fluid {
    @include make-container($gutter: 2rem);
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    transition: padding 200ms ease;
    padding-right: 0;
    .openPreview & {
        @include media-breakpoint-up(xl) {
            padding-right: calc(332px - 2rem);
            transition: padding 200ms ease;
        }
    }
}

header {
    padding-top: calc(env(safe-area-inset-top) + 1rem);
    padding-bottom: 1rem;
    .top-nav {
        display: flex;
        align-items: center;
        .search {
            flex-grow: 1;
        }
        .header-buttons {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: flex-end;
        }
    }
    .logo {
        width: 3rem;
        flex-shrink: 0;
    }
}

main {
    flex-grow: 1;
    position: relative;
}

.container-fluid {
    @include media-breakpoint-up(md) {
        padding: 0 1.5rem;
    }
    @include media-breakpoint-up(lg) {
        padding: 0 1.75rem;
    }
    @include media-breakpoint-up(xl) {
        padding: 0 2rem;
    }
}

footer {
    padding-top: 0.5rem;
    padding-bottom: calc(0.5rem + env(safe-area-inset-bottom));
}

aside {
    &.preview {
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 10;
        left: 100vw;
        width: 300px;
        background-color: $primary;
        color: white;
        transform: translateX(0);
        transition: transform 200ms ease;
        margin: 1rem;
        border-radius: 1.5rem;
        padding: 4rem 1rem 1rem;
        .openPreview & {
            transform: translateX(-332px);
        }
        .close-me {
            border-radius: 1rem;
        }
    }
}
.closeNav {
    position: fixed;
    background-color: transparent;
    top: 0;
    right: 0;
    bottom: 0;
    left: 122px;
    z-index: 4;
    margin: 0;
    padding: 0;
    &:focus {
        outline: none;
        box-shadow: none;
    }
}
.nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
.nav-item {
    padding: 1rem;
    line-height: 0;
    border-radius: 0.5rem;
    clip-path: url('#squircle');
    color: $gray-700;
    position: relative;
    display: inline-block;
    .header-buttons & {
        @include media-breakpoint-down(sm) {
            padding: 0.5rem;
        }
    }
    .material-symbols-rounded {
        line-height: 1;
    }
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        color: black;
    }
    &.active {
        color: $primary;
    }
}

:focus {
    outline: none;
    box-shadow: 0 0 0 $btn-focus-width rgba(black, 0.3);
}

.count {
    position: absolute;
    display: block;
    bottom: 50%;
    left: 50%;
    font-size: 0.75rem;
    padding: 0.25rem;
    border-radius: 0.125rem;
    line-height: 1;
    &.danger {
        color: white;
        background-color: $danger;
    }
}

.search-wrapper {
    position: relative;
    .search-label,
    .material-symbols-rounded {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 2rem;
    }
    .material-symbols-rounded {
        left: 0.5rem;
        color: rgba(black, 0.3);
    }
    .search-label {
        left: 2.5rem;
        pointer-events: none;
        opacity: 1;
        visibility: visible;
        font-size: $font-size-base;
    }
    .search-input {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.5rem 0.75rem 0.5rem 2.5rem;
        font-size: $font-size-base;
        font-weight: 400;
        line-height: 1.5;
        background-color: #fff;
        background-clip: padding-box;
        border: none;
        border-radius: 1rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        &:not(:placeholder-shown) {
            ~ .search-label {
                opacity: 0;
            }
        }
        &:focus {
            @extend .shadow;
        }
    }
}

.profileImage {
    width: 2.5rem;
    border-radius: 1.25rem;
    margin: -0.5rem;
}

h1,
h2,
h3,
h4,
h5 {
    .badge {
        font-size: 0.75rem;
        border-radius: $border-radius-sm;
        vertical-align: top;
    }
}

.widget-collection {
    display: flex;
    margin-bottom: 1rem;
    .widget {
        word-wrap: break-word;
        background-color: $card-bg;
        background-clip: border-box;
        border: $card-border-width solid $card-border-color;
        @include border-radius($card-border-radius);
        @extend .shadow;
        padding: $card-spacer-x * 0.75;
        @include media-breakpoint-up(md) {
            padding: $card-spacer-x;
        }
        width: 100%;
        flex: 0 0 calc(33.3333333333% - 0.3333333333rem);
        max-width: calc(33.3333333333% - 0.3333333333rem);
        @include media-breakpoint-down(md) {
            &:not(:nth-child(3n)) {
                margin-right: 0.5rem;
            }
        }
        @include media-breakpoint-up(md) {
            flex: 0 0 200px;
            max-width: 200px;
            &:not(:last-child) {
                margin-right: 0.5rem;
            }
        }
        @include media-breakpoint-up(lg) {
            flex: 0 0 240px;
            max-width: 240px;
        }

        text-align: left;
        span {
            display: block;
            line-height: 1;
            color: $body-color;
            &.widget-icon {
                font-size: 2rem;
                margin-bottom: 1rem;
            }
            &.widget-label {
                font-size: 0.75rem;
                margin-bottom: 0.25rem;
                font-weight: 700;
                color: $primary;
                text-transform: uppercase;
            }
            &.widget-value {
                font-size: 1.25rem;
                @include media-breakpoint-up(md) {
                    font-size: 1.5rem;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 2rem;
                }
                font-weight: 600;
            }
        }
    }
    @include media-breakpoint-up(lg) {
        &.widgets-lg-small {
            .widget {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                .widget-icon {
                    margin-top: -0.25rem;
                    margin-bottom: 0;
                }
            }
        }
    }
}

table.table {
    &.table-hover {
        tbody {
            tr {
                border-radius: 1rem;
                @media (pointer: fine) {
                    &:hover {
                        box-shadow: $box-shadow;
                        color: $body-color;
                        border: none;
                    }
                }
                @media (pointer: coarse) {
                    &.inactive {
                        &:hover {
                            background-color: transparent;
                        }
                    }
                }
                > :first-child {
                    border-top-left-radius: 1rem;
                    border-bottom-left-radius: 1rem;
                }
                > :last-child {
                    border-bottom-right-radius: 1rem;
                    border-top-right-radius: 1rem;
                }
            }
        }
    }
    tbody {
        th {
            font-weight: 400;
        }
    }
    tr {
        cursor: pointer;
        @include media-breakpoint-up(md) {
            > :first-child {
                padding-left: 1rem;
            }
            > :last-child {
                padding-right: 1rem;
            }
            &.inactive {
                color: $text-muted;
            }
        }

        td,
        th {
            font-size: $font-size-sm;
            vertical-align: middle;
            &.stack-contents {
                span,
                small {
                    display: block;
                }
            }
            @include media-breakpoint-up(md) {
                &.stack-md-contents {
                    span,
                    small {
                        display: block;
                    }
                }
            }
            @include media-breakpoint-up(lg) {
                &.stack-lg-contents {
                    span,
                    small {
                        display: block;
                    }
                }
            }
            @include media-breakpoint-up(xl) {
                &.stack-xl-contents {
                    span,
                    small {
                        display: block;
                    }
                }
            }
            &.bulk-select {
                width: 44px;
            }
            &.bookmark-column,
            &.icon-column {
                @include media-breakpoint-up(md) {
                    text-align: center;
                    width: 88px;
                }
            }
        }
        .material-symbols-rounded {
            font-size: 1.125rem;
        }
    }

    // DEFINING MOBILE BEHAVIOUR
    @include media-breakpoint-down(sm) {
        thead {
            display: none;
        }
        tbody {
            width: 100%;
            display: block;
        }
        tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
            position: relative;
            padding: 0.75rem;
            &:not(.inactive) {
                background-color: white;
                box-shadow: $box-shadow;
            }
            &.inactive {
                &.disabled {
                    border: 1px dashed $text-muted;
                    color: $text-muted;
                }
                &.enabled {
                    border: 1px solid $text-muted;
                    color: $body-color;
                }
            }
            td {
                padding: 0;
                &.empty,
                &.mobile-hide,
                &.bulk-select {
                    display: none;
                }
                &.active-column,
                &.mobile-icon-right,
                &.bookmark-column {
                    position: absolute;
                    top: 0.75rem;
                }
                &.bookmark-column {
                    right: 0.75rem;
                }
                &.active-column {
                    right: 2.25rem;
                }
                &.mobile-icon-right {
                    right: 3.75rem;
                }
                &.mobile-muted {
                    color: $text-muted;
                }
            }
        }
        .mobile-title {
            font-size: 1rem;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
}
section.table-content {
    @include media-breakpoint-up(md) {
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        padding: 0 1.5rem 3rem;
        margin: 0 -1.5rem -3rem;
        width: calc(100% + 3rem);
    }
    @include media-breakpoint-up(lg) {
        padding: 0 1.75rem;
        margin: 0 -1.75rem;
        width: calc(100% + 3.5rem);
    }
    @include media-breakpoint-up(xl) {
        padding: 0 2rem;
        margin: 0 -2rem;
        width: calc(100% + 4rem);
    }
}

* {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.4) transparent;
}
body {
    overflow-y: overlay;
}
::-webkit-scrollbar {
    width: 12px;
    height: 12px; /* width of the entire scrollbar */
    // overflow-y: overlay;
}
::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4); /* color of the scroll thumb */
    border-radius: 6px; /* roundness of the scroll thumb */
    border: 4px solid $body-bg; /* creates padding around scroll thumb */
}

.preview-toolbar {
    display: flex;
    position: absolute;
    top: 1rem;
    left: 1rem;
    button,
    a {
        padding: 0.5rem;
        line-height: 0;
        border-radius: 0.5rem;
        color: white;
        display: block;
        .material-symbols-rounded {
            font-size: 1.25rem;
        }
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
            color: black;
        }
    }
}

.material-symbols-rounded {
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
    &.edit_square {
        margin-top: -0.166666666666667em;
    }
}

.bookmark-column {
    &.bookmarked {
        .material-symbols-rounded {
            font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
            color: $danger;
        }
    }
}

.custom-control {
    &.bulk-select-checkbox {
        padding-left: 1rem;
        label {
            &::before,
            &::after {
                left: -1rem;
            }
        }
    }
}

svg.clipping-path {
    position: absolute;
    width: 0;
    height: 0;
}